import React from 'react'

const IconHuman = () => (
  <svg
    viewBox="0 0 1024 1024"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
  >
    <path
      d="M437.76 389.12c20.48-20.48 45.2608-30.72 74.24-30.72s53.76 10.24 74.24 30.72c20.48 20.48 30.72 45.2608 30.72 74.24V512a40.96 40.96 0 0 0 81.92 0v-48.64c0-51.6096-18.2272-95.6416-54.784-132.1472S563.6608 276.48 512 276.48s-95.6416 18.2272-132.1472 54.7328-54.784 80.5376-54.784 132.096V512a184.8832 184.8832 0 0 0 32 104.5504 40.96 40.96 0 1 0 67.8912-45.824A104.2432 104.2432 0 0 1 407.04 512v-48.64c0-28.9792 10.24-53.76 30.72-74.24zM708.4032 757.0944c-62.464-28.5696-127.9488-42.8544-196.4032-42.8544s-133.9392 14.336-196.4032 42.8544a497.5616 497.5616 0 0 0-159.5392 116.224 40.96 40.96 0 0 0 60.1088 55.6032 416.4096 416.4096 0 0 1 133.4784-97.28c51.712-23.6544 105.7792-35.4816 162.3552-35.4816s110.6944 11.776 162.3552 35.4304a416.4608 416.4608 0 0 1 133.4784 97.3312 40.96 40.96 0 0 0 60.1088-55.6032 497.5616 497.5616 0 0 0-159.5392-116.224z"
    ></path>
    <path
      d="M208.64 602.88v1.792h5.7344c9.6256 2.7136 19.6096 4.608 30.1056 4.608h22.4768c2.8672 0 4.864-2.048 5.8368-4.608h7.5264V439.04c0-64 22.6304-118.5792 67.84-163.84S448 207.36 512 207.36s118.5792 22.6304 163.84 67.84c45.2096 45.2608 67.84 99.84 67.84 163.84v113.5104c0 11.1104-3.9424 20.6336-11.8272 28.5184s-17.408 11.8272-28.5184 11.8272H587.776a57.344 57.344 0 0 0-46.592-24.9344 58.368 58.368 0 1 0 0 116.736c17.408 0 32.6144-8.0384 43.3152-20.1216h118.784c30.976 0 57.344-10.9568 79.2576-32.8192 7.5776-7.5776 13.3632-15.9232 18.3296-24.6272a111.5136 111.5136 0 1 0 10.0352-216.576c-9.472-63.0784-37.0176-118.6304-84.3776-166.0416C667.2896 165.2736 595.7632 135.68 512 135.68s-155.2896 29.5936-214.528 88.832c-47.36 47.4112-74.9056 102.9632-84.3776 166.0416A111.5136 111.5136 0 0 0 132.608 497.408c0 49.152 31.9488 90.4704 76.032 105.472z"
    ></path>
  </svg>
)
export default IconHuman
