import React from 'react'

const IconClose = () => (
  <svg
    viewBox="0 0 1024 1024"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
  >
    <path
      d="M459.2128 519.5264l-256.512 256.4608a42.6496 42.6496 0 0 0 60.416 60.3136l256.4096-256.4608 256.4608 256.512a42.6496 42.6496 0 0 0 60.3136-60.416l-256.4096-256.4096 256.4096-256.4608a42.6496 42.6496 0 0 0-60.3136-60.3136l-256.4608 256.4096L263.168 202.752A42.6496 42.6496 0 1 0 202.752 263.0656l256.4608 256.4608z"
    ></path>
  </svg>
)
export default IconClose
