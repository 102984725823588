const dict = {
  'en-US': {
    "你好，我是Cooper智能客服。你可以简单描述一下问题，例如：": "Hello, I am your assistant in Cooper. You can briefly describe the problem, for example:",
    "如何修改团队空间成员权限？": "How to modify team space member permissions?",
    "文档有查看权限，如何申请编辑权限？": "How to apply for edit permission?",
    "请输入你想了解的问题": "Please enter the question you want to know",
    "内容由AI生成，可通过来源或人工核实使用": "The content is generated by AI and can be verified through reference or customer service",
    "你觉得不满意的原因是？": "Why are you dissatisfied",
    "回答错误": "Incorrect answer",
    "答非所问": "give an irrelevant answer",
    "没有帮助": "No help",
    "参考来源：": "Reference:",
    "生成了部分内容后点停止生成内容。": "After generating some content, click to stop generating the content.",
    "你好，你的问题我无法回答，可以点击 [Cooper反馈群]({link}) 前往D-Chat找相关人员解答。": "Hello, I am unable to answer your question. You can click  [\"Cooper Feedback\"]({link}) and ask relevant personnel for help.",
    "你好，你可以点击 Cooper反馈群 前往D-Chat找相关人员解答。": "Hello, You can click  \"Cooper Feedback\" and ask relevant personnel for help.",
    "停止生成": "Stop generating",
    "有帮助": "Helpful",
    "无帮助": "No help",
    "重新生成": "Regenerate",
    "问答内容不支持emoji": "The Q&A content does not support emojis",
    "抱歉，您已经失败多次，请调整内容或检查网络状态后重试。": "Sorry, you have failed multiple times. Please adjust the content or check the network status and try again.",
    "Cooper智能客服": "Cooper AI Assistant",
    "联系DC客服": "D-Chat Feedback Group"
  },
  'zh-CN': {
    "你好，我是Cooper智能客服。你可以简单描述一下问题，例如：": "你好，我是Cooper智能客服。你可以简单描述一下问题，例如：",
    "如何修改团队空间成员权限？": "如何修改团队空间成员权限？",
    "文档有查看权限，如何申请编辑权限？": "文档有查看权限，如何申请编辑权限？",
    "请输入你想了解的问题": "请输入你想了解的问题",
    "内容由AI生成，可通过来源或人工核实使用": "内容由AI生成，可通过来源或人工核实使用",
    "你觉得不满意的原因是？": "你觉得不满意的原因是？",
    "回答错误": "回答错误",
    "答非所问": "答非所问",
    "没有帮助": "没有帮助",
    "参考来源：": "参考来源：",
    "生成了部分内容后点停止生成内容。": "生成了部分内容后点停止生成内容。",
    "你好，你的问题我无法回答，可以点击 [Cooper反馈群]({link}) 前往D-Chat找相关人员解答。": "你好，你的问题我无法回答，可以点击 [Cooper反馈群]({link}) 前往D-Chat找相关人员解答。",
    "你好，你可以点击 Cooper反馈群 前往D-Chat找相关人员解答。": "你好，你可以点击 Cooper反馈群 前往D-Chat找相关人员解答。",
    "停止生成": "停止生成",
    "有帮助": "有帮助",
    "无帮助": "无帮助",
    "重新生成": "重新生成",
    "问答内容不支持emoji": "问答内容不支持emoji",
    "抱歉，您已经失败多次，请调整内容或检查网络状态后重试。": "抱歉，您已经失败多次，请调整内容或检查网络状态后重试。",
    "Cooper智能客服": "Cooper智能客服",
    "联系DC客服": "联系DC客服"
  },
}

// @ts-ignore
export const getLocale = () : 'zh-CN' | 'en-US' => window.g_di18n_lang || 'zh-CN'

export const intl = (key: string, slot: {[replaceKey: string]: string} = {}) => {
  const lang = getLocale();
  const text = (dict[lang] as {[key: string]: string})?.[key]
  if (!text) {
    console.warn(`locales data "${key}" not exists`)
    return key;
  } else {
    Object.keys(slot).forEach(sk => {
      text.replace('{' + sk + '}', slot[sk]);
    })
  }
  return text
}
