import React from 'react'

const IconLike = () => (
  <svg
    className="didoc-component-icon didoc-component-icon-like"
    width="200px"
    height="200px"
    viewBox="0 0 1024 1024"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M910.08 395.52a114.56 114.56 0 0 0-90.88-41.6H631.04V243.2a151.68 151.68 0 0 0-46.08-111.36 152.32 152.32 0 0 0-111.36-46.72 43.52 43.52 0 0 0-39.04 25.6L294.4 426.88H217.6a122.88 122.88 0 0 0-89.6 34.56 137.6 137.6 0 0 0-43.52 83.2v280.32A128 128 0 0 0 128 906.88a128 128 0 0 0 87.68 32H768a119.68 119.68 0 0 0 119.04-101.12l53.12-345.6a113.92 113.92 0 0 0-30.08-96.64z m-632.96 458.24h-60.16a46.08 46.08 0 0 1-31.36-11.52 48 48 0 0 1-15.36-26.24v-262.4a54.4 54.4 0 0 1 16.64-29.44A43.52 43.52 0 0 1 217.6 512h59.52z m523.52-29.44a30.08 30.08 0 0 1-34.56 28.8H362.24V482.56L499.2 174.72a75.52 75.52 0 0 1 25.6 17.28 69.76 69.76 0 0 1 21.12 51.2v153.6a42.24 42.24 0 0 0 42.88 42.24h231.04a33.28 33.28 0 0 1 26.24 12.16 30.72 30.72 0 0 1 7.68 27.52z" />
  </svg>
)
export default IconLike
