import React from 'react'

const IconCooper = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    version="1.1"
    width="22"
    height="22"
    viewBox="0 0 22 22"
  >
    <g>
      <g>
        <path
          d="M4.69457,6.16864C1.94828,7.23363,0,9.91223,0,13.0482C0,17.1192,3.28325,20.4194,7.33333,20.4194C8.66907,20.4194,9.92139,20.0605,11,19.4333C12.0787,20.0604,13.331,20.4194,14.6667,20.4194C18.7168,20.4194,22,17.1192,22,13.0482C22,9.91229,20.0518,7.23376,17.3057,6.16871C16.4723,3.4643699999999997,13.9644,1.5,11.0001,1.5C8.03581,1.5,5.52794,3.46433,4.69457,6.16864ZM4.40073,8.225719999999999C2.78747,9.21903,1.71111,11.00728,1.71111,13.0482C1.71111,16.1693,4.22827,18.6995,7.33333,18.6995C9.66477,18.6995,11.6647,17.273,12.5166,15.2411C12.3248,14.9564,12.2224,14.6206,12.2224,14.2767C12.2224,13.3268,12.9884,12.5568,13.9335,12.5568C14.8785,12.5568,15.6446,13.3268,15.6446,14.2767C15.6446,15.1779,14.9527,15.9262,14.0585,15.9921C13.6872,16.8478,13.159,17.619,12.5104,18.2689C13.1744,18.5463,13.9027,18.6994,14.6667,18.6994C17.7717,18.6994,20.2889,16.1693,20.2889,13.0482C20.2889,9.9271,17.7717,7.39695,14.6667,7.39695C11.6219,7.39695,9.14236,9.82986,9.04728,12.8672C9.50525,13.1891,9.77781,13.715,9.77781,14.2767C9.77781,15.2266,9.01172,15.9967,8.0667,15.9967C7.12168,15.9967,6.35559,15.2266,6.35559,14.2767C6.35559,13.8837,6.48938,13.5028,6.73485,13.1969C5.32817,11.9983,4.42757,10.21808,4.40073,8.225719999999999ZM11.0001,3.21995C12.819,3.21995,14.406,4.21842,15.248,5.69983C15.0562,5.6847,14.8623,5.677,14.6667,5.677C11.1275,5.677,8.17387,8.19711,7.48503,11.5495C6.63453,10.66528,6.11123,9.46111,6.11123,8.13408C6.11123,5.4200800000000005,8.30006,3.21995,11.0001,3.21995Z"
          fillRule="evenodd"
          fill="#1C5BFF"
          fillOpacity="1"
        />
      </g>
    </g>
  </svg>
)
export default IconCooper
