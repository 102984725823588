import React, {useContext, useEffect, useRef} from 'react';
import Messages from './Messages';
import {add_messages, Role, RootContext} from './Root';
import Greeting from './Greeting';
import { uuid } from '../browser/utils';
import Textarea from "./Textarea";
import { intl } from '../browser/locales';
// import debounce from "lodash/debounce";
// import {getQaHistory} from "../request";
// import InviteProtocol from "./InviteProtocol";
// import GlobalStyle from "./GlobalStyle";

// const MaxScrollTop = 30;

function Chat() {
  const { dispatch } = useContext(RootContext);
  // const requestRef = useRef(false);
  // const scrollBottomRef = useRef<number|null>(null);
  const domRef = useRef<HTMLDivElement>(null);

  // const formatMessage = (list: []) => {
  //   return list.map((item: any) => {
  //     return {
  //       id: item.messageId,
  //       role: item.role,
  //       body: item.role,
  //       createTime: item.createTime
  //     };
  //   })
  // };

  // const handleScroll = debounce((event: any) => {
  //   const scrollContainer = event.target;
  //   scrollBottomRef.current = scrollContainer.scrollHeight - scrollContainer.scrollTop;
  //   if (offsetId === null || requestRef.current) return;

  //   if (scrollContainer.scrollTop <= MaxScrollTop) {
  //     requestRef.current = true;
  //     getQaHistory(offsetId)
  //         .then(({data, offsetId}: any) => {
  //           dispatch({
  //             type: set_offset_id,
  //             value: offsetId
  //           });
  //           dispatch({
  //             type: add_history_messages,
  //             value: formatMessage(data)
  //           });
  //         })
  //         .finally(() => {
  //           requestRef.current = false;
  //           dispatch({
  //             type: init_history,
  //             value: true
  //           });
  //         })
  //   }
  // }, 300)

  useEffect(() => {
    const messages = [{
      id: uuid(),
      role: Role.assistant,
      body: <Greeting dispatch={dispatch}/>,
    }];

    dispatch({
      type: add_messages,
      value: messages
    });
  }, [])

  // useLayoutEffect(() => {
  //   const scrollContainer = domRef.current!;
  //   const scrollBottom = scrollBottomRef.current;
  //   if (scrollBottom === null) {
  //     scrollContainer.scrollTop = scrollContainer.scrollHeight - scrollContainer.clientHeight;
  //   } else {
  //     scrollContainer.scrollTop = scrollContainer.scrollHeight - scrollBottom;
  //   }
  // }, [historyMessages]);

  return (
    <section className={`icschat`}>
      {/* { isOpen && <GlobalStyle/> } */}
      
      <main className={'icschat-main'}>
        <div ref={domRef} className={'icschat-main-content'}>
          <Messages/>
        </div>
        <footer className={'icschat-footer'}>
          <Textarea/>

          <div className={'icschat-footer-statement'}>
            {intl('内容由AI生成，可通过来源或人工核实使用')}
            {/* <InviteProtocol/> */}
          </div>
        </footer>
      </main>
    </section>
  );
}

export default Chat;
