import React from 'react';

function Style() {
    return (
        <style>
            {`
        .icschat-wrapper {
          height: 100%;
        }
        .icschat-wrapper.fullscreen {
          top: 0;
        }
        .icschat-message-timestamp {
           position: absolute;
           left: 50%;
           bottom: 100%;
           transform: translate(-50%, -10px);
           font-size: 12px;
           color: #909499;
        }
        .icschat-placeholder-wrapper {
          padding: 8px;
          margin: 12px 20px;
          cursor: pointer;
        }
        .icschat-history:empty {
          display: none;
        }
        .icschat-history-expand {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 12px;
            color: #909499;
            padding: 0 16px;
        }
        .icschat-history-expand::before {
            content: '';
            position: absolute;
            top: 50%;
            left: 0;
            width: 78px;
            height: 1px;
            background: rgba(0, 0, 0, 0.05);
            transform: translate(0, -50%);
        }
        .icschat-history-expand::after {
            content: '';
            position: absolute;
            top: 50%;
            right: 0;
            width: 78px;
            height: 1px;
            background: rgba(0, 0, 0, 0.05);
            transform: translate(0, -50%);
        }
        .icschat-invite-button {
            color: #656A72;
            cursor: pointer;
        }
        .icschat-invite-button:hover {
            color: #644BFC;
        }
        .icschat-placeholder {
          font-size: 14px;
          color: #4E555D;
          text-align: center;
          margin-top: 60px;
        }
        .icschat-star-icon {
            margin: 0 4px;
        }
        .icschat-placeholder-title {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 20px;
            font-weight: 500;
            color: #222A35;
            margin-bottom: 6px;
        }
        .icschat-placeholder-title-icon {
            width: 26px;
            margin-right: 6px;
        }
        .icschat {
          display: flex;
          flex-direction: column;
          height: 100%;
        }
        .icschat-header-title {
          margin-left: 8px;
        }
        .icschat-header-beta-tag {
          width: 30px;
          height: 13px;
          font-size: 10px;
          font-weight: 500;
          line-height: 13px;
          text-align: center;
          color: #FFFFFF;
          border-radius: 20px;
          background: rgba(4, 127, 254, 0.5);
          margin-left: 8px;
        }
        .icschat-header-icon-button {
          display: flex;
          width: 28px;
          height: 28px;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          border-radius: 4px;
          color: #333;
          padding: 4px;
        }
        .icschat-header-icon-button + .icschat-header-icon-button {
          margin-left: 8px;
        }
        .icschat-header-icon-button:hover {
          background: rgba(0, 0, 0, 0.08);
        }
        .icschat-header-icon-button svg {
          fill: #7A7F86;
        }
        .icschat-header-icon-button:hover svg {
          fill: #222A35;
        }
        :where(.icschat-header [data-title]) {
          position: relative;
        }
        .icschat-header span[data-title]:hover::before {
          content: attr(data-title);
          position: absolute;
          left: 50%;
          bottom: 100%;
          transform: translate(-50%, -4px);
          background: rgba(0, 0, 0, 0.8);
          border-radius: 4px;
          padding: 4px 8px;
          font-size: 12px;
          color: #fff;
          white-space: nowrap;
          line-height: normal;
        }
        .icschat-main {
          position: relative;
          display: flex;
          flex-direction: column;
          flex: 1;
          overflow: hidden;
        }
        .icschat-loading {
            color: #909499;
            font-size: 12px;
            text-align: center;
        }
        .icschat-main-content {
          flex: 1;
          padding: 12px 16px;
          height: 100%;
          overflow: auto;
          box-sizing: border-box;
        }
        .icschat-footer {
          padding: 8px 16px 8px 16px;
        }
        .icschat-textarea-container {
          position: relative;
        }
        .icschat-textarea-options {
          display: flex;
          gap: 8px;
          margin-bottom: 10px;
        }
        .icschat-textarea-button {
          display: inline-flex;
          align-items: center;
          cursor: pointer;
          border: 1px solid #D5DBE0;
          font-size: 14px;
          line-height: 16px;
          color: #222A35;
          background: #F9FAFE;
          border-radius: 7px;
          padding: 5px 8px;
        }
        .icschat-textarea-button-icon {
          margin-right: 2px;
        }
        .icschat-textarea-button:not(:disabled):hover {
            background: #EBEEF1;
        }
        .icschat-textarea-button:disabled {
          cursor: not-allowed;
          color: #909499;
          border-color: rgb(213, 219, 224, 0.6);
        }
        .icschat-textarea-box {
          background: #D8DEE3;
          padding: 1px;
          border-radius: 12px;
          overflow: hidden;
        }
        .icschat-textarea-box:focus-within {
          background: linear-gradient(102deg, #047FFE 22%, #25B3FF 100%);
        }
        .icschat-textarea-box-wrapper {
          border-radius: 11px;
          background: #FFF;
          padding: 12px 0;
        }
        .icschat-textarea-box-footer {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 12px;
        }
        .icschat-textarea-error {
            color: #FF563B;
            font-size: 12px;
        }
        .icschat-textarea-send-button {
          border: none;
          width: 26px;
          height: 26px;
          border-radius: 7px;
          cursor: pointer;
          padding: 0;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          background: rgba(26, 110, 255, 1);
        }
        .icschat-textarea-send-button:hover {
           background: rgba(26, 110, 255, 0.8);
        }
        .icschat-textarea-send-button:disabled {
            cursor: not-allowed;
            background: #D3D4D7;
        }
        .icschat-textarea-send-button-icon {
            width: 12px;
        }
        .icschat-textarea {
          box-sizing: border-box;
          display: block;
          width: 100%;
          height: 22px;
          outline: none;
          resize: none;
          border: none;
          font-size: 14px;
          line-height: 22px;
          caret-color: #5A79F7;
          color: #222A35;
          padding: 0 12px;
          margin-bottom: 4px;
        }
        .icschat-textarea::placeholder {
            color: #A7AAAE;
        }
        .icschat-link {
          color: #047FFE;
          cursor: pointer;
        }
        .icschat-link:hover {
          color: #047FFECC;
        }
        .icschat-footer-statement {
          font-size: 11px;
          line-height: 15px;
          color: #A7AAAE;
          text-align: center;
          margin-top: 8px;
        }
        .icschat-message-wrapper {
          position: relative;
          word-break: break-all;
        }
        .icschat-message-wrapper:first-child {
          margin-top: 0;
        }
        .icschat-message-wrapper a {
          color: #047FFE;
        }
        .icschat-message-wrapper a:hover {
          color: #047FFECC;
        }
        .icschat-user-message-wrapper {
          margin-top: 50px;
        }
        .icschat-assistant-message-wrapper {
          margin-top: 20px;
        }
        .icschat-message-wrapper:last-child {
          margin-bottom: 50px;
        }
        .icschat-assistant-message-wrapper {
        }
        .icschat-user-message-wrapper {
          display: flex;
          justify-content: flex-end;
        }
        .icschat-common-message {
          font-size: 14px;
          padding: 10px 12px;
          border-radius: 8px;
          color: #222A35;
        }
        .icschat-user-message-wrapper .icschat-common-message {
          background: #DAE9FF;
        }
        .icschat-greeting-home {
          background: #FFFFFF;
          padding: 10px 12px;
          border-radius: 8px;
          font-size: 14px;
          color: #222A35;
          border: 1px solid #E4EEF9;
        }
        .icschat-greeting-home p {
          margin: 0;
        }
        .icschat-greeting-home p + p {
          margin-top: 0px;
        }
        .icschat-greeting-home-icon {
          width: 328px;
          height: auto;
          margin-top: 12px;
          margin-left: -4px;
          vertical-align: top;
        }
        .icschat-input {
          // background-image: linear-gradient(97deg, #1E3ADC 0%, #FF0A6C 52%, #FEDA00 99%);
          box-shadow: 0px 4px 10px 0px rgba(156, 164, 175, 0.2);
          border-radius: 6px;
          border: 1px solid #D9E2EB;
          align-items: unset;
        }
        .icschat-input:focus-within {
          background-image: linear-gradient(97deg, #1E3ADC 0%, #FF0A6C 52%, #FEDA00 99%);
          box-shadow: 0px 4px 10px 0px rgba(156, 164, 175, 0.2);
          border-radius: 6px;
          border: 1px solid transparent;
          border-left: 1px solid #1E3ADC;
          border-right: 1px solid #FEDA00;
          align-items: unset;
        }
        .icschat-input-inner {
          display: flex;
          align-items: center;
          width: 100%;
          background: #ffffff;
          border-radius: 5px;
        }
        .icschat-block-button {
          display: block;
          width: 100%;
          height: 36px;
          font-size: 14px;
          font-weight: 500;
          color: #644BFC;
          border: none;
          border-radius: 4px;
          background: rgba(100, 75, 252, 0.08);
          margin: 12px auto;
          cursor: pointer;
        }
        .icschat-block-button:hover {
          background: rgba(100, 75, 252, 0.12);
        }
        .icschat-block-button:disabled {
          color: rgba(100, 75, 252, 0.6);
          cursor: not-allowed;
        }
        .icschat-horizontal-divider {
          border-top: none;
          border-color: #F2F3F3;
          margin: 7px 0;
        }
        .icschat-card-tip {
          font-size: 12px;
          line-height: 14px;
          color: #A7AAAE;
        }
        .icschat-text {
          padding: 10px 12px;
        }
        .icschat-button {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 14px;
          color: #2A54FA;
          border: none;
          border-radius: 4px;
          background: rgba(121, 147, 252, 0.1);
          cursor: pointer;
        }
        .icschat-button:disabled {
            cursor: not-allowed;
        }
        .icschat-button + .icschat-button {
          margin-left: 4px;
        }
        .icschat-button .icschat-icon {
          margin-right: 4px;
        }
        .icschat-icon {
          display: inline-block;
          line-height: 1;
          font-size: 0;
        }
        .icschat-assistant-message-wrapper .icschat-common-message {
          background: #fff;
          border: 1px solid #E4EEF9;
        }
        .icschat-greeting {
          padding: 12px;
          font-size: 14px;
          color: #222A35;
          background: #fff;
          border-radius: 8px;
          border: 1px solid #E4EEF9;
        }
        .icschat-pending{
          display: flex;
          height: 6px
        }
        .icschat-pending span{
            display: inline-block;
            width: 6px;
            height: 100%;
            margin-right: 10px;
            border-radius: 50%;
            background: #047FFE;
            animation: livechat_load 1.04s ease infinite;
        }
        .icschat-pending span:last-child{
            margin-right: 0px; 
        }
        @keyframes livechat_load{
            0%{
                opacity: 1;
                transform: scale(1.3);
            }
            100%{
                opacity: 0;
                transform: scale(.3);
            }
        }
        .icschat-pending span:nth-child(1){
            animation-delay:0.13s;
        }
        .icschat-pending span:nth-child(2){
            animation-delay:0.26s;
        }
        .icschat-pending span:nth-child(3){
            animation-delay:0.39s;
        }
        .icschat-generate-summary-pending-wrapper {
          position: relative;
          width: 67px;
          background: linear-gradient(121deg, #047FFE 23%, #04A6FE 100%);
          padding: 1px;
          border-radius: 8px;
          box-sizing: content-box;
        }
        .icschat-generate-summary-pending {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 67px;
          height: 42px;
          background: #FFFFFF;
          border-radius: 7px;
        }
        .icschat-message-card {
          position: relative;
          padding: 1px;
          border-radius: 8px;
          color: #222A35;
          font-size: 14px;
        }
        .icschat-html hr {
          border-bottom-color: #F2F3F3;
          border-top-width: 0px;
        }
        .icschat-html img {
          display: block;
          cursor: zoom-in;
          max-width: 324px;
          max-height: 200px;
        }
        .icschat-html table {
            border-collapse: collapse;
            border: 1px solid #DEE0E3;
        }
        .icschat-html table th,
        .icschat-html table td {
            vertical-align: top;
            text-align: left;
            border: 1px solid #DEE0E3;
        }
        .icschat-html ul,
        .icschat-html ol {
           padding-left: 0;
        }
        .icschat-html ul ul,
        .icschat-html ul ol,
        .icschat-html ol ol,
        .icschat-html ol ul {
           padding-left: 15px;
        }
        .icschat-html p,
        .icschat-html li {
          margin: 2px 0;
        }
        .icschat-html p {
            white-space: pre-wrap;
        }
        .icschat-html li {
            list-style: auto;
            list-style-position: inside;
        }
        .icschat-html p:first-child,
        .icschat-html li:first-child {
          margin-top: 0;
        }
        .icschat-html li p:first-child {
          display: inline;
        }
        .icschat-html p:last-child,
        .icschat-html li:last-child {
          margin-bottom: 0;
        }
        .icschat-button-stop {
          position: absolute;
          top: 100%;
          padding: 5px 11px;
          margin-top: 6px;
          border: 1px solid #EBF3FB;
          background: #FFFFFF;
          border-radius: 6px;
          cursor: pointer;
          display: flex;
          align-items: center;
          color: #222A35;
          white-space: nowrap;
          font-size: 13px;
        }
        .icschat-button-stop svg {
          width: 14px;
          height: 14px;
        }
        .icschat-button-stop:hover {
          background: #EEEEEF;
        }
        .icschat-message-card-layout {
          padding: 12px;
          background: #FFF;
          border-radius: 7px;
        }
        .icschat-message-card.pending {
          background-image: linear-gradient(102deg, #047FFE 22%, #25B3FF 100%);
        }
        .icschat-message-card:not(.pending) {
          // border: 1px solid #E4EEF9;
          background-image: linear-gradient(109deg, #E4EEF9, #E4EEF9);
        }
        .icschat-generate-summary-footer {
          width: 100%;
          display: flex;
          align-items: center;
          margin-top: 16px;
        }
        .icschat-generate-summary-footer:empty {
          display: none;
        }
        .icschat-message {
          position: fixed;
          top: 85px;
          left: 50%;
          transform: translate(-50%, 0);
          z-index: 2001;
        }
        .icschat-message-content {
          background: #E9F8F3;
          padding: 16px;
          border-radius: 6px;
          border: 1px solid #2ECDA4;
          box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.1);
          font-size: 16px;
          color: rgba(0, 0, 0, 0.9);
          line-height: 22px;
          display: flex;
          align-items: flex-start;
        }
        .icschat-message-content-icon {
          margin-right: 8px;
          font-size: 20px;
        }
        #icschat-drop-container {
          min-height: 100%;
          margin: -16px;
          padding: 16px;
        }
        #icschat-drop-container.dragover * {
          pointer-events: none;
        }
        .icschat-drop-mask {
          position: absolute;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          background: rgba(50, 38, 126, 0.1);
        }
        #icschat-drop-container.dragover .icschat-drop-mask {
          background: rgba(50, 38, 126, 0.14);
        }
        .icschat-drop-mask-tip {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          padding: 40px;
          background: #F1F1F7;
          border-radius: 8px;
          font-size: 14px;
          color: #222A35;
          width: 308px;
          height: 288px;
          border: 1px dashed rgba(100, 75, 252, 0.4);
          box-sizing: border-box;
          white-space: pre-wrap;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          text-align: center;
        }
        .icschat-drop-mask-tip img {
          margin-bottom: 8px;
        }
        .icschat-summary-card {
          border-radius: 8px;
          font-size: 14px;
          width: 100%;
          padding: 10px 12px;
          background: #E4E2FF;
          color: #222A35;
        }
        .icschat-summary-card-link {
          display: flex;
          align-items: center;
          color: #222A35;
          margin-top: 6px;
          cursor: pointer;
          border-radius: 4px;
          background: rgba(255, 255, 255, 0.5);
          padding: 11px;
        }
        .icschat-summary-card-link:hover {
          background: rgba(255, 255, 255, 0.7);
        }
        .icschat-summary-card-link-text {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          margin-left: 6px;
        }
        .icschat-icon-button {
          height: 22px;
          border: 1px solid #EEEEEF;
          border-radius: 14px;
          background: #fff;
          font-size: 16px;
          padding: 3px 10px;
        }
        .icschat-icon-button svg {
          fill: #909499;
        }
        .icschat-icon-button + .icschat-icon-button {
          margin-left: 8px;
        }
        .icschat-icon-button:hover {
          background: #EEEEEF;
        }
        .icschat-icon-button:hover svg {
          fill: #222A35;
        }
        .icschat-icon-button.active {
          background: rgba(4, 127, 254, 0.1);
        }
        .icschat-icon-button.active svg {
          fill: #1A6EFF;
        }
        :where(.icschat-wrapper [data-title]) {
          position: relative;
        }
        .icschat-wrapper button[data-title]:hover::before {
          content: attr(data-title);
          position: absolute;
          left: 50%;
          bottom: 100%;
          transform: translate(-50%, -4px);
          background: rgba(0, 0, 0, 0.8);
          border-radius: 4px;
          padding: 4px 8px;
          font-size: 12px;
          color: #fff;
          white-space: nowrap;
          line-height: normal;
        }
        .icschat-cursor-blink {
          display: inline-block;
          width: 2px;
          height: 16px;
          vertical-align: middle;
          margin-top: -4px;
          margin-left: 4px;
          animation: livechat_blink 0.8s infinite both;
          background: linear-gradient(175deg, #5A79F7 25%, #B2A0FF 100%);
        }
        .icschat-scope-select {
            position: relative;
            display: inline-flex;
        }
        .icschat-scope-select.is-open .icschat-textarea-button,
        .icschat-scope-select .icschat-textarea-button:hover {
            background: #EBEEF1;
        }
        .icschat-textarea-button-icon-arrow {
            margin-left: 4px;
        }
        .icschat-scope-select.is-open .icschat-textarea-button .icschat-textarea-button-icon-arrow {
            transform: rotate(180deg);
        }
        .icschat-scope-select-menu {
          position: absolute;
          bottom: 100%;
          left: 0;
          background: #fff;
          border-radius: 8px;
          padding: 8px;
          transform: translateY(-8px);
          background: #FFFFFF;
          border: 1px solid #E8E9EA;
          box-shadow: 0px 4px 20px 0px rgba(60, 68, 88, 0.16);
          margin: 0;
        }
        .icschat-scope-select-menu-item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            list-style-type: none;
            cursor: pointer;
            white-space: nowrap;
            width: 224px;
            line-height: 1;
            border-radius: 4px;
            padding: 8px;
            font-size: 14px;
            color: #644BFC;
        }
        .icschat-scope-select-menu-item-left {
            display: inline-flex;
            align-items: center;
        }
        .icschat-scope-select-menu-item-right .icschat-scope-select-menu-item-icon {
            margin-right: 0;
        }
        .icschat-scope-select-menu-item-tip {
            font-size: 10px;
            color: #909499;
        }
        .icschat-scope-select-menu-item + .icschat-scope-select-menu-item {
            margin-top: 2px;
        }
        .icschat-scope-select-menu-item-icon {
            margin-right: 8px;
            font-size: 16px;
        }
        .icschat-scope-select-menu-item:not(.disabled):hover {
            background: #F2F3F3;
        }
        .icschat-scope-select-menu-item.active {
            color: #644BFC;
        }
        .icschat-scope-select-menu-item.disabled {
            color: #909499;
            cursor: not-allowed;
        }
        .icschat-toolbar-wrapper {
            position: absolute;
            bottom: 270px;
            right: 100%;
        }
        .icschat-toolbar-menu {
            position: absolute;
            right: 16px;
            bottom: 100%;
            padding-bottom: 20px;
            padding: 4px;
            border-radius: 21px;
            background: #FFFFFF;
            box-sizing: border-box;
            border: 1px solid #EBEEF1;
            box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.1);
            margin-bottom: 10px;
            display: flex;
            flex-direction: column;
        }
        .icschat-toolbar-menu::after {
            content: '';
            position: absolute;
            height: 12px;
            top: 100%;
            left: 0;
            width: 100%;
            z-index: -1;
        }
        .icschat-toolbar-menu-item {
            width: 34px;
            height: 34px;
            padding: 8px;
            border-radius: 17px;
            color: #6A707C;
            box-sizing: border-box;
            cursor: pointer;
            transition: all .2s ease-in-out;
            overflow: hidden;
            text-align: center;
        }
        .icschat-toolbar-menu-item:hover {
            overflow: initial;
        }
        .icschat-toolbar-menu-item.is-expand {
            margin-bottom: 4px;
        }
        .icschat-toolbar-menu-item.is-fold {
            height: 0;
            padding: 0;
        }
        .icschat-toolbar-menu-item[data-title]:hover::before {
          content: attr(data-title);
          position: absolute;
          right: 100%;
          top: 50%;
          transform: translate(-10px, -50%);
          background: rgba(0, 0, 0, 0.8);
          border-radius: 4px;
          padding: 4px 8px;
          font-size: 12px;
          color: #fff;
          white-space: nowrap;
          line-height: normal;
          z-index: 1;
        }
        .icschat-toolbar-mini[data-title]:hover::before {
          content: attr(data-title);
          position: absolute;
          right: 100%;
          top: 50%;
          transform: translate(-6px, -50%);
          background: rgba(0, 0, 0, 0.8);
          border-radius: 4px;
          padding: 4px 8px;
          font-size: 12px;
          color: #fff;
          white-space: nowrap;
          line-height: normal;
        }
        .icschat-toolbar[data-title]:hover::before {
          content: attr(data-title);
          position: absolute;
          right: 100%;
          top: 50%;
          transform: translate(-6px, -50%);
          background: rgba(0, 0, 0, 0.8);
          border-radius: 4px;
          padding: 4px 8px;
          font-size: 12px;
          color: #fff;
          white-space: nowrap;
          line-height: normal;
        }
        .icschat-toolbar-menu-item:hover {
            background: rgba(100, 75, 252, 0.1);
            color: #644BFC;
        }
        .icschat-toolbar-menu-item-icon .didoc-iconfont {
            font-size: 18px;
            line-height: 1;
            color: inherit;
        }
        .icschat-toolbar-wrapper.is-mini {
            transform: translateX(40px);
        }
        .icschat-toolbar-mini {
            box-sizing: border-box;
            position: absolute;
            top: 0;
            left: 0;
            transform: translateY(-50%);
            width: 16px;
            height: 16px;
            padding: 8px 4.5px;
            border-radius: 8px;
            background: rgba(0, 0, 0, 0.2);
            cursor: pointer;
            visibility: hidden;
        }
        .icschat-toolbar-mini::after {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            width: 28px;
            height: 28px;
            transform: translate(-50%, -50%);
        }
        .icschat-toolbar-mini:hover {
            background: rgba(0, 0, 0, 0.3);
        }
        .icschat-toolbar-mini-icon {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 7px;
            height: 1.5px;
            background: #fff;
        }
        .icschat-toolbar.is-expand .icschat-toolbar-text {
            max-width: 100px;
        }
        .icschat-toolbar.is-expand .icschat-toolbar-mini {
            visibility: visible;
        }
        .icschat-toolbar-hotspot {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            width: 20px;
            height: 42px;
            z-index: -1;
        }
        .icschat-toolbar {
            position: absolute;
            right: 16px;
            cursor: pointer;
            padding: 9px;
            border-radius: 21px;
            background: linear-gradient(136deg, #EFE4FF 0%, #E1EAFF 99%);
            box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.1);
            white-space: nowrap;
            z-index: 1;
        }
        .icschat-toolbar.disabled {
            cursor: not-allowed;
        }
        .icschat-toolbar-content {
            display: flex;
            align-items: center;
        }
        .icschat-toolbar.disabled .icschat-toolbar-content {
            opacity: 0.6;
        }
        .icschat-toolbar-icon {
            width: 24px;
            vertical-align: top;
        }
        .icschat-toolbar-text {
            flex: 1;
            overflow: hidden;
            box-sizing: border-box;
            font-size: 15px;
            font-weight: 500;
            color: #644BFC;
            text-indent: 6px;
            max-width: 0px;
            transition: max-width .2s ease-in-out;
        }
        .icschat-toolbar-guide {
            position: absolute;
            right: 100%;
            background: linear-gradient(313deg, #879CFE 3%, #889AFE 4%, #A957FC 88%, #FC7C51 100%);
            border-radius: 12px;
            box-shadow: 0px 2px 36px 0px rgba(179, 182, 209, 0.5);
            padding: 1px;
            transform: translate(-85px, -112px);
            transition: all .2s ease-in-out;
        }
        .icschat-toolbar-guide.is-expand {
            transform: translate(-155px, -112px);
        }
        .icschat-toolbar-guide-arrow {
            position: absolute;
            left: 100%;
            top: 50%;
            display: flex;
            align-items: center;
            transform: translate(0, -50%);
        }
        .icschat-toolbar-guide-arrow-line {
            width: 12px;
            height: 1.4px;
            background: linear-gradient(90deg, #889AFE 1%, #889AFE 2%, #687EFF 100%) ;
        }
        .icschat-toolbar-guide-arrow-icon {
            width: 8px;
            height: 8px;
            background: #687EFF;
            border-radius: 50%;
        }
        .icschat-toolbar-guide-box {
            box-sizing: border-box;
            width: 272px;
            height: 266px;
            padding: 16px;
            background: linear-gradient(270deg, #E6F1FF 0%, #F8F0FD 100%);
            border-radius: 12px;
        }
        .icschat-toolbar-guide-text {
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
            color: #333333;
        }
        .icschat-toolbar-guide-icon {
            border-radius: 8px;
            background: #FFFFFF;
            border: 0.5px solid #E9E8FF;
            margin-top: 10px;
            width: 240px;
            vertical-align: top;
        }
        .icschat-toolbar-guide-footer {
            display: flex;
            margin-top: 16px;
            justify-content: flex-end;
        }
        .icschat-toolbar-guide-close {
            font-size: 12px;
            line-height: 18px;
            display: flex;
            align-items: center;
            color: #FFFFFF;
            padding: 5px 12px;
            border-radius: 4px;
            border-radius: 4px;
            background: #644BFC;
            cursor: pointer;
            border: none;
            outline: none;
        }
        /*这里设置动画blink*/
        @keyframes livechat_blink {
          0% {
            opacity: 1;
          }
          100% {
            opacity: 0;
          }
        }
        .didoc-component-icon {
          width: 16px;
          height: 16px;
        }
        .didoc-component-icon + span {
          margin-left: 6px;
        }
      `}
        </style>
    );
}

export default Style;
