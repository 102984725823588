import React, {useContext, useMemo} from 'react';
import { RootContext } from './Root';
import MessageWrapper from './MessageWrapper';

function Messages() {
  const { store: { messages, historyMessages, isShowHistoryMessages } } = useContext(RootContext);

  const data = useMemo(() => {
    if (isShowHistoryMessages) {
      return historyMessages.concat(messages);
    }

    return messages;
  }, [messages, historyMessages, isShowHistoryMessages])

  return (
    <div className={'icschat-messages-wrapper'}>
      {
        data.map((item) => <MessageWrapper key={item.id} {...item}/>)
      }
    </div>
  );
}

export default Messages;
