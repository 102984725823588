// @ts-nocheck
export function fullScreen() {
  const el = document.documentElement;
  const rfs = el.requestFullScreen
    || el.webkitRequestFullScreen
    || el.mozRequestFullScreen
    || el.msRequestFullScreen;

  if (typeof rfs !== 'undefined' && rfs) {
    rfs.call(el);
  } else if (typeof window.ActiveXObject !== 'undefined') {
    const wscript = new window.ActiveXObject('WScript.Shell');
    if (wscript) {
      wscript.SendKeys('{F11}');
    }
  }
}


export function exitFullScreen() {
  const el = document;
  
  const cfs = el.cancelFullScreen
    || el.webkitCancelFullScreen
    || el.mozCancelFullScreen
    || el.exitFullScreen;

  if (typeof cfs !== 'undefined' && cfs) {
    cfs.call(el);
  } else if (typeof window.ActiveXObject !== 'undefined') {
    const wscript = new window.ActiveXObject('WScript.Shell');
    if (wscript) {
      wscript.SendKeys('{F11}');
    }
  }
}