// @ts-nocheck
import {
  exitFullScreen,
  fullScreen,
} from './util';
import ReactModal from 'react-modal';
import Viewer from './js/viewer';
import React from 'react'

// @ts-ignore
// window.Viewer = Viewer; // Viewer类

let fidx;
let urlPreviewImg;
let imgFiles;
let container;
let imgViewerDefaultUrl = [];
let zIndex = 100001000;

const imgViewerConfig = {
  inline: true,
  backdrop: false,
  button: true,
  focus: true,
  fullScreen: false,
  fullScreenAble: true,
  loop: true,
  loading: true,
  movable: true,
  keyboard: true,
  rotatable: true,
  scalable: false,
  tooltip: true,
  zoomOnWheel: true,
  slideOnTouch: true,
  zoomable: true,
  navbar: true,
  transition: true,
  downloadable: false, // 不支持下载
  originalImgAble: false, // 原图
  isTiny: false, // 是否接入tiny
  container: document.getElementById('cooper-image-preview-body'),
  toolbar: {
    zoomIn: true,
    zoomOut: true,
    oneToOne: true,
    prev: true,
    play: false,
    next: true,
    rotateLeft: true,
    movable: false,
    flipHorizontal: true,
    flipVertical: true,
  },
};

class CooperImagePreview extends React.Component<any> {
  constructor(props) {
    super(props);
    this.state = {
      hasOperation: props.hasOperation,
      hasFooter: false, // 标识是不是需要footer
      fname: '', // 保存文件名称
      type: '', // 类型
      url: '', // 真实的url
      text: '', // 解析出来的文本
      hasDownloadIcon: false,
      hasDeleteIcon: false,
      videoKind: 'old',
      isFullScreen: false,
      file: {},
    };
    fidx = props.fidx;
    zIndex = props.zIndex || 100001000;
    container = document.getElementById('cooper-image-preview-body');
    imgFiles = props.imgFiles || [];
  }

  componentDidMount() {
    imgViewerDefaultUrl = []
    setTimeout(() => {
      this.loadPreview();
    }, 0);
    document.addEventListener('fullscreenchange', this.exitFullscreenHandler);
    document.addEventListener(
      'webkitfullscreenchange',
      this.exitFullscreenHandler,
    );
    document.addEventListener(
      'mozfullscreenchange',
      this.exitFullscreenHandler,
    );
    document.addEventListener('MSFullscreenChange', this.exitFullscreenHandler);
    document.addEventListener('click', this.clickCooperImageOutside);
  }

  componentWillUnmount() {
    document.removeEventListener(
      'fullscreenchange',
      this.exitFullscreenHandler,
    );
    document.removeEventListener(
      'webkitfullscreenchange',
      this.exitFullscreenHandler,
    );
    document.removeEventListener(
      'mozfullscreenchange',
      this.exitFullscreenHandler,
    );
    document.removeEventListener(
      'MSFullscreenChange',
      this.exitFullscreenHandler,
    );
    document.removeEventListener(
      'click',
      this.clickCooperImageOutside,
    );
  }

  exitFullscreenHandler = () => {
    if (
      !document.fullscreenElement
      // @ts-ignore
      && !document.webkitFullscreenElement
      // @ts-ignore
      && !document.mozFullScreenElement
      // @ts-ignore
      && !document.msFullscreenElement
    ) {
      const PreviewDom = document.querySelector('.cooper-image-preview');
      PreviewDom.classList.remove('viewer-container-fullscreen');
      const cancelFullScreen = document.querySelector(
        '.viewer-container-fullscreen-cancel',
      );
      cancelFullScreen && PreviewDom.removeChild(cancelFullScreen);
      // @ts-ignore
      exitFullScreen(PreviewDom);
      this.setState({
        isFullScreen: false,
      }, () => {
        // @ts-ignore
        if (this.type === 'image') {
          // @ts-ignore
          document.querySelector('.viewer-list').style.display = 'block'
        }
      });
    }
  };
  clickCooperImageOutside = (event) => {
    
    let cDom = document.querySelector('.cooper-image-preview .viewer-canvas')?.firstChild;
    let pDom = document.querySelector('.cooper-image-preview .viewer-canvas');
    let tDom = event.target;
    // @ts-ignore
    if (cDom && tDom && pDom && !cDom.contains(tDom) && pDom.contains(tDom) && !this.state.isFullScreen) {
      // @ts-ignore
      this.props.closePreview();
    }
  }

  getImgIdx = (src, fileItems) => {
    let idx = 0;
    fileItems.forEach((f, index) => {
      if (f === src) {
        idx = index;
      }
    });
    return idx;
  };

  appendCancelFullScreen = (PreviewDom) => {
    const cancelFullScreen = document.createElement('div');
    cancelFullScreen.classList.add('viewer-container-fullscreen-cancel');
    cancelFullScreen.setAttribute('title', '退出全屏');
    PreviewDom.appendChild(cancelFullScreen);
    cancelFullScreen.addEventListener('click', () => {
      cancelFullScreen.parentNode.removeChild(cancelFullScreen);
      // @ts-ignore
      exitFullScreen(PreviewDom);
      this.setState({
        isFullScreen: false,
      });
    });
  };


  renderImgPreview = () => {
    let _this = this;
    // viewer 实例
    let viewer = new Viewer(container, {
      ...imgViewerConfig,
      localeLanguage: 'zh-cn',
      hide() {
        // @ts-ignore
        _this.props.closePreview();
      },
      view(image) {
        if (image.detail && image.detail.image) {
          const previewDom = document.querySelector('.cooper-image-preview');
          const imgOriginalDom = previewDom.querySelector('.viewer-original-img');
          // @ts-ignore
          if (imgOriginalDom) imgOriginalDom.style.display = 'none';
          const imgDownloadDom = previewDom.querySelector('.viewer-download');
          // @ts-ignore
          if (imgDownloadDom) imgDownloadDom.style.display = 'none';
          const imgViewButtonDom = previewDom.querySelector('.viewer-button');
          // @ts-ignore
          if (imgViewButtonDom) imgViewButtonDom.style.display = 'none';
        }
      },
      viewed(image) {
        const data = image.target.viewer
        let imgOriginalDom = document.querySelector('.viewer-original-img');
        let imgStatusDom = document.querySelector('.viewer-one-to-one')
        let imgDom = document.querySelector('.viewer-transition')
        if (imgStatusDom.classList.contains('viewer-one-to-one-auto')) imgStatusDom.classList.remove('viewer-one-to-one-auto');
        if (imgViewerDefaultUrl[data.index]) {
          !imgOriginalDom.classList.contains('viewer-original-img-has') && imgOriginalDom.classList.add('viewer-original-img-has')
          imgOriginalDom.innerHTML = '已是原图'
          // @ts-ignore
          imgDom.src = imgViewerDefaultUrl[data.index]
          // @ts-ignore
          viewer.resize()
        } else {
          imgOriginalDom.classList.contains('viewer-original-img-has') && imgOriginalDom.classList.remove('viewer-original-img-has')
          imgOriginalDom.innerHTML = '查看原图'
        }
        // 存储当前查看图片的名字
        _this.setState({
          fname: image?.detail?.image?.alt || '暂无',
          file: imgFiles[_this.getImgIdx(image?.detail?.image?.src, imgFiles)],
        });
      },
      initStatus() {
        let imgStatusDom = document.querySelector('.viewer-one-to-one')
        if (imgStatusDom && imgStatusDom.classList?.contains('viewer-one-to-one-auto')) {
          imgStatusDom.classList.remove('viewer-one-to-one-auto');
        }
      },
      title(data) {
        return `${data.index + 1}/${data.length}`;
      },
      
      fullScreen() {
        const PreviewDom = document.querySelector('.cooper-image-preview');
        if (PreviewDom) {
          // @ts-ignore
          if (!_this.state.isFullScreen) {
            _this.appendCancelFullScreen(PreviewDom);
            // @ts-ignore
            fullScreen(PreviewDom);
            PreviewDom.classList.add('viewer-container-fullscreen');
            _this.setState({
              isFullScreen: true,
            });
          }
        }
      },
    });
    // window.viewer = viewer;
    // @ts-ignore
    viewer.view(_this.getImgIdx(urlPreviewImg, imgFiles));
  };

  loadPreview = () => {
    const f = imgFiles[fidx];
    const previewUrl = ''
    if (f) {
      urlPreviewImg = f;
      container = document.getElementById('cooper-image-preview-body');
      imgFiles.forEach((fItem) => {
        const image = new Image();
        image.src = fItem;
        // image.alt = fItem.name;
        container.appendChild(image);
      });
      imgViewerDefaultUrl.length = imgFiles.length
      return this.setState({
        file: f,
        type: 'image',
        url: previewUrl,
        fname: '' //f.name,
      });
    }
    // return window.fetch(previewUrl).then((data) => data.text());
  };
  render() {
    // @ts-ignore
    const { type } = this.state;

    const rmStyle = {
      overlay: {
        zIndex, // 智能智能助手（100000000）里的图片预览，应该更高
        backgroundColor: 'transparent',
      },
      content: {
        border: 0,
        backgroundColor: 'transparent',
      },
    };

    return (
      <ReactModal
        isOpen={true}
        appElement={document.body}
        style={rmStyle}>
        <div className="cooper-image-preview">
          <div
            className="cooper-image-preview-body"
            id="cooper-image-preview-body">
            
            {type === 'image' && (() => setTimeout(this.renderImgPreview, 10))()}
            {type === 'unknown' && (
              <span className="cp-unknown">暂不支持此文件预览</span>
            )}
          </div>
        </div>
      </ReactModal>
    );
  }
}

export { CooperImagePreview };