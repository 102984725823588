import React, {PropsWithChildren} from 'react';
import {Message, Role} from './Root';
import CommonMessage from './CommonMessage';
import {formatTimestamp} from "../browser/utils";

function User(props: PropsWithChildren<Message>) {
  return (
    <div className={'icschat-message-wrapper icschat-user-message-wrapper'}>
      <div className={'icschat-message-timestamp'}>{formatTimestamp(props.createTime)}</div>
      {props.children}
    </div>
  )
}

function Assistant(props: PropsWithChildren<Message>) {
  return (
    <div className={'icschat-message-wrapper icschat-assistant-message-wrapper'}>
      {props.children}
    </div>
  )
}

function MessageWrapper(props: Message) {
  const { role, body } = props;
  const Wrapper = role === Role.user ? User : Assistant;
  const children = typeof body === 'string' ? <CommonMessage text={body}/> : body;

  return (
    <Wrapper {...props}>
      { children }
    </Wrapper>
  );
}

export default MessageWrapper
