import React, { useReducer, Dispatch, ReactNode } from 'react';

interface Action {
  type: string;

  [key: string]: any;
}

export enum Role {
  'user' = 'user',
  'assistant' = 'assistant',
  'system_error' = 'system_error'
}

export interface Message {
  id: string;
  role: Role;
  body: string | ReactNode;
  createTime: number;
  group?: string;
}

interface InitState {
  isLoading: boolean;
  isHistoryInit: boolean;
  offsetId: string;
  isShowHistoryMessages: boolean;
  historyMessages: Message[];
  messages: Message[];
  config: {
    [key: string]: any
  };
  isMini: boolean;
}

export const initState = {
  isLoading: false,
  isHistoryInit: false,
  isShowHistoryMessages: false,
  historyMessages: [],
  messages: [],
  config: {},
  offsetId: '',
  isMini: false
}

export const update_isLoading = 'update_isLoading';
export const add_messages = 'add_messages';
export const add_history_messages = 'add_history_messages';
export const show_history_messages = 'show_history_messages';
export const init_history = 'init_history';
export const set_offset_id = 'set_offset_id';
export const reset_store = 'reset_store';
export const toggle_mini = 'toggle_mini';
export const toggle_open = 'toggle_open';
export const reducer = (state: InitState, action: Action): InitState=> {
  switch(action.type) {
    case update_isLoading:
      return {
        ...state,
        isLoading: action.value
      }
    case add_messages:
      return {
        ...state,
        messages: [...state.messages, ...action.value]
      }
    case show_history_messages:
      return {
        ...state,
        isShowHistoryMessages: action.value
      }
    case add_history_messages:
      return {
        ...state,
        historyMessages: [...action.value, ...state.historyMessages]
      }
    case init_history:
      return {
        ...state,
        isHistoryInit: true
      }
    case set_offset_id:
      return {
        ...state,
        offsetId: action.value
      }
    case toggle_mini:
      return {
        ...state,
        isMini: action.value
      }
    case toggle_open:
      if (!action.value) {
        return {
          ...state,
          isLoading: false,
          isHistoryInit: false,
          isShowHistoryMessages: false,
          historyMessages: [],
          messages: [],
          offsetId: '',
          isMini: false
        }
      }
      return {
        ...state
      }
    case reset_store:
      return {
        ...initState
      }
    default:
      return state
  }
}

interface RootContextProps {
  store: InitState;
  dispatch: Dispatch<Action>
}

export const RootContext = React.createContext<RootContextProps>({
  store: initState,
  dispatch: () => {
    throw new Error("GlobalContext 未定义")
  }
});

interface RootProps {
  children: ReactNode;
  state: Partial<InitState>;
}

export const Root = (props: RootProps) => {
  const { children, state } = props;
  const [store, dispatch] = useReducer(reducer, {...initState, ...state});

  return (
    <RootContext.Provider value={{store, dispatch}}>
      {children}
    </RootContext.Provider>
  );
}

