import React from 'react';

interface CommonMessageProps {
  text: string;
}

function CommonMessage(props: CommonMessageProps) {
  const { text } = props;

  return (
      <div className={'icschat-common-message'}>
        {text}
      </div>
  );
}

export default CommonMessage;
