import React from 'react'

const IconStop = ({fill = '#FF563B'}) => (
  <svg
    className='didoc-component-icon'
    width="200px"
    height="200px"
    viewBox="0 0 1024 1024"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    fill={fill}
  >
    <path d="M161.865143 161.865143Q16.822857 306.907429 16.822857 512t145.042286 350.134857Q306.907429 1007.177143 512 1007.177143t350.134857-145.042286Q1007.177143 717.092571 1007.177143 512t-145.042286-350.134857Q717.092571 16.822857 512 16.822857T161.865143 161.865143z m66.706286 633.563428Q111.177143 678.034286 111.177143 512t117.394286-283.428571Q345.965714 111.177143 512 111.177143t283.428571 117.394286Q912.822857 345.965714 912.822857 512t-117.394286 283.428571Q678.034286 912.822857 512 912.822857t-283.428571-117.394286z m81.92-389.12v211.236572c0 53.028571 43.008 96.036571 96.036571 96.036571h211.163429c53.028571 0 96.036571-43.008 96.036571-96.036571V406.381714c0-53.028571-43.008-96.036571-96.036571-96.036571H406.528c-53.028571 0-96.036571 43.008-96.036571 96.036571z" />
  </svg>
)
export default IconStop
