import axios from 'axios';
import {baseURL} from "../browser/constant";


export const axiosInstance = axios.create({
    baseURL: baseURL
});

const config = {withCredentials: true,}

export function resetQaContext() {
    const headers = {
        'Content-Type': 'application/x-www-form-urlencoded'
    }
    return axiosInstance.post(`/cooper_gateway/callback/v1/llm/bot/context/reset`, null, {headers, ...config})
        .then((_data: any) => {
            return {
                success: true
            };
        })
        .catch(() => {
            return {
                success: false
            };
        });
}

export function getQaHistory(offsetId = '') {
    return axiosInstance.get(`/cooper_gateway/callback/v1/llm/bot/history?size=20&offsetId=${offsetId}`, config)
        .then(({ data: { data } }: any) => {
            return {
                data: data.historyMessages.reverse(),
                offsetId: data.offsetId
            };
        })
}