import { intl } from "./locales";

export enum IsLike {
    empty,
    like,
    dislike,
}

export enum Status {
    pending,
    progress,
    fulfilled,
    rejected,
}

export const MaxErrorTimes = 3;
// TODO: 兜底报错，config传进来
export const ResponseTip = {
    fail: intl('你好，你的问题我无法回答，可以点击 [Cooper反馈群]({link}) 前往D-Chat找相关人员解答。', {
      link: 'https://im.xiaojukeji.com/channel?uid=381828&token=7f1b9187b09b7607ad1796b4cc76cc95&id=146847933529642240'
    }),
    timeLimit: '抱歉，您已经失败多次，请调整内容或检查网络状态后重试。',
    unknown: '未获取到相关内容', // 服务器错误时返回
    401: '未授权'
}
// process.env.NODE_ENV === 'development' ? 'https://cooper-test.didichuxing.com' : 
export const baseURL = window.location.origin

export const CloseCooperAiPanelEvent = 'CloseCooperAiPanelEvent';