import React, { useState, useCallback, ReactNode, createRef } from 'react'
import {
  DraggableModal,
  DraggableModalProps,
  DraggableModalProvider,
} from '@didi/ant-design-draggable-modal'
import 'antd/dist/antd.css'
import '@didi/ant-design-draggable-modal/dist/index.css'
import '@didi/cooper-image-preview/dist/index.css'
import ReactDOM from 'react-dom'
import AiCustomerServiceHeader from './AiCustomerServiceHeader'
import Chat from './components/Chat'
import { Root } from './components/Root'
import Style from './components/Style'
import { onFeedbackClickOmegaEvent } from './browser/utils'

interface AiCustomerServiceProps {
  children: ReactNode
}

// interface IModalProps extends DraggableModalProps {
//   visible: boolean
// }
interface IICSProps {
  config?: {
    [key: string]: any
  }
}

interface AiCustomerServiceModalProps extends DraggableModalProps, IICSProps {
  visible: boolean
}
export const AiCustomerServiceContent = ({ config = {} }: IICSProps) => {
  return (
    <Root state={{ config }}>
      <Style />
      <div className={`icschat-wrapper`}>
        <Chat />
      </div>
    </Root>
  )
}

export const AiCustomerServiceModal = (props: AiCustomerServiceModalProps) => {
  return (
    <DraggableModal
      title={props.title || <AiCustomerServiceHeader onCancel={props.onCancel} feedBackDc={props?.config?.feedBack_dc} />}
      closable={false}
      footer={null}
      onOk={props.onOk}
      onCancel={props.onCancel}
      visible={props.visible}
      initialX={props.initialX}
      initialY={props.initialY}
      initialHeight={props.initialHeight}
      initialWidth={props.initialWidth}
      initialZIndex={props.initialZIndex}
    >
      <AiCustomerServiceContent config={props.config} />
    </DraggableModal>
  )
}
// 智能客服窗口只有一个
// 再次点击，可能更新x,y,z定位
export const AiCustomerServiceButton = (props: AiCustomerServiceProps) => {
  const [visible, setVisible] = useState(false)
  const [initialState, setInitialState] = useState({})
  const onOk = useCallback(() => setVisible(true), [])
  const onCancel = useCallback(() => setVisible(false), [])

  const onToggle = useCallback((e) => {
    const p = calculatePopupPosition(e, 380, 600, 'top')
    setInitialState({
      initialX: p.x,
      initialY: p.y,
      initialWidth: 380,
      initialHeight: 600,
      initialZIndex: 100000000,
    })
    setVisible((v) => !v)
  }, [])

  return (
    <>
      <div onClick={onToggle}>{props.children}</div>
      <AiCustomerServiceModal visible={visible} onOk={onOk} onCancel={onCancel} {...initialState} />
    </>
  )
}

let ICSModalRef: any = createRef()
let ICSModalVisible = false
export const showModal = (
  modalProps: DraggableModalProps,
  icsProps: IICSProps,
  e?: React.MouseEvent,
) => {
  onFeedbackClickOmegaEvent()
  // 如果现在就有一个“智能客服”
  // const activityModal: HTMLElement | null = document.querySelector('.ai-customer-service-modal')
  // if (activityModal) {
  //   zIndex && (activityModal.style.zIndex = zIndex)
  //   x && (activityModal.style.left = x + 'px')
  //   y && (activityModal.style.top = y + 'px')
  //   return
  // }
  let { x, y } = e
    ? calculatePopupPosition(
        e,
        modalProps.initialWidth || 380,
        modalProps.initialHeight || 600,
        'bottom-left',
        12,
        {x: 0, y: 46}
      )
    : { x: 0, y: 0 }
  const area = x && y ? { ...modalProps, initialX: x, initialY: y } : { ...modalProps }
  const onOk = () => {
    ICSModalRef.current.setVisible(false)
  }
  const onCancel = () => {
    ICSModalRef.current.setVisible(false)
  }

  ICSModalRef.current = {
    setVisible: (visible: boolean) => {
      ICSModalVisible = visible
      let container = document.getElementById('ai-customer-service-modal-root')
      if (!container) {
        container = document.createElement('div')
        container.id = 'ai-customer-service-modal-root'
        document.body.appendChild(container)
      }
      ReactDOM.render(
        <DraggableModalProvider>
          <AiCustomerServiceModal
            visible={visible}
            onOk={onOk}
            onCancel={onCancel}
            {...area}
            config={icsProps.config}
          />
        </DraggableModalProvider>,
        container, //.getElementById('modal-root'),
      )
    },
  }
  ICSModalRef.current.setVisible(true)
  setTimeout(() => {
    const textarea: HTMLElement | null = document.querySelector('.icschat-textarea');
    textarea?.focus()
  }, 100);
}
export const hideModal = () => {
  ICSModalRef?.current?.setVisible(false)
}
export const toggleModal = (
  modalProps: DraggableModalProps,
  icsProps: IICSProps,
  e?: React.MouseEvent,
) => {
  if (ICSModalVisible) {
    hideModal()
  } else {
    showModal(modalProps, icsProps, e)
  }
}

type Direction =
  | 'top'
  | 'bottom'
  | 'left'
  | 'right'
  | 'top-left'
  | 'top-right'
  | 'bottom-left'
  | 'bottom-right'

export const calculatePopupPosition = (
  event: React.MouseEvent,
  width: number,
  height: number,
  direction: Direction,
  edge = 12, // 安全边距
  offset = {x: 0, y: 0} // 偏移
): { x: number; y: number } => {
  let x = 0
  let y = 0
  if (!event) return { x, y }
  const boundElement = event.currentTarget
  const rect = boundElement.getBoundingClientRect()
  // const clientX = event.clientX
  // const clientY = event.clientY
  const screenWidth = window.innerWidth
  const screenHeight = window.innerHeight

  switch (direction) {
    case 'top':
      x = rect.left + rect.width / 2 - width / 2 + offset.x
      y = rect.top - height + offset.y
      if (x < edge) x = edge
      if (y < edge) y = edge
      break
    case 'bottom':
      x = rect.left + rect.width / 2 - width / 2 + offset.x
      y = rect.bottom + offset.y
      if (x < edge) x = edge
      if (y + height > screenHeight - edge) y = screenHeight - height - edge
      break
    case 'left':
      x = rect.left - width + offset.x
      y = rect.top + rect.height / 2 - height / 2 + offset.y
      if (x < edge) x = edge
      if (y < edge) y = edge
      break
    case 'right':
      x = rect.right + offset.x
      y = rect.top + rect.height / 2 - height / 2 + offset.y
      if (x + width > screenWidth - edge) x = screenWidth - width - edge
      if (y < edge) y = edge
      break
    case 'top-left':
      x = rect.right - width + offset.x
      y = rect.top - height + offset.y
      if (x < edge) x = edge
      if (y < edge) y = edge
      break
    case 'top-right':
      x = rect.left + offset.x
      y = rect.top - height + offset.y
      if (x + width > screenWidth - edge) x = screenWidth - width - edge
      if (y < edge) y = edge
      break
    case 'bottom-left':
      x = rect.right - width + offset.x
      y = rect.bottom + offset.y
      if (x < edge) x = edge
      if (y + height > screenHeight - edge) y = screenHeight - height - edge
      break
    case 'bottom-right':
      x = rect.left + offset.x
      y = rect.bottom + offset.y
      if (x + width > screenWidth - edge) x = screenWidth - width - edge
      if (y + height > screenHeight - edge) y = screenHeight - height - edge
      break
  }

  return { x, y }
}
