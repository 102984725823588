import React from 'react'
import IconCooper from './components/icon/IconCooper'
import IconClose from './components/icon/IconClose'
import { intl } from './browser/locales'
import IconHuman from './components/icon/IconHuman'
import { isDesktopDC, isIpad } from './browser/utils'
export const openNewWindow = (url: string = '') => {
  // 兼容新旧版safari
  if (isDesktopDC || isIpad()) {
    window.open(url)
  } else {
    let win = window.open('about:blank')
    win?.location.replace(url)
  }
}
const AiCustomerServiceHeader: React.FC<any> = ({ onCancel, feedBackDc }) => (
  <div className='icschat-header' style={{display: 'contents'}}>
    <IconCooper />
    <span className={'icschat-header-title'}>{intl('Cooper智能客服')}</span>
    <span className={'icschat-header-beta-tag'}>Beta</span>
    <div style={{ flex: 1 }}></div>
    {feedBackDc && (
      <span
        data-title={intl('联系DC客服')}
        className={'icschat-header-icon-button'}
        onClick={() => openNewWindow(feedBackDc)}
      >
        <IconHuman />
      </span>
    )}
    <span className={'icschat-header-icon-button'} onClick={onCancel}>
      <IconClose />
    </span>
  </div>
)

export default AiCustomerServiceHeader
