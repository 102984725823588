import { getWindowSize } from './getWindowSize'
import { clamp } from './clamp'

const mapObject = <T>(o: { [key: string]: T }, f: (value: T) => T): { [key: string]: T } =>
    Object.assign({}, ...Object.keys(o).map(k => ({ [k]: f(o[k]) })))

// ID for a specific modal.
export type ModalID = string

// State for a specific modal.
export interface ModalState {
    x: number
    y: number
    width: number
    height: number
    zIndex: number
    visible: boolean
}

export interface ModalStateProps {
  initialX?: number
  initialY?: number
  initialWidth?: number
  initialHeight?: number
  initialZIndex?: number
}

// State of all modals.
export interface ModalsState {
    maxZIndex: number
    windowSize: {
        width: number
        height: number
    }
    modals: {
        [key: string]: ModalState
    }
}

export const initialModalsState: ModalsState = {
    maxZIndex: 100000000,
    windowSize: getWindowSize(),
    modals: {},
}

export const initialModalState: ModalState = {
    x: 0,
    y: 0,
    width: 380,
    height: 600,
    zIndex: 100000000,
    visible: false,
}

const getInitialModalState = ({
    initialX = initialModalState.x,
    initialY = initialModalState.y,
    initialWidth = initialModalState.width,
    initialHeight = initialModalState.height,
    initialZIndex = initialModalState.zIndex,
}: ModalStateProps) => {
    return {
        ...initialModalState,
        x: initialX,
        y: initialY,
        width: initialWidth,
        height: initialHeight,
        zIndex: initialZIndex,
    }
}

export type Action =
    | { type: 'show'; id: ModalID; intialState: { initialX?: number; initialY?: number, initialWidth?: number; initialHeight?: number } }
    | { type: 'hide'; id: ModalID }
    | { type: 'focus'; id: ModalID }
    | { type: 'unmount'; id: ModalID }
    | { type: 'mount'; id: ModalID; intialState: { initialX?: number; initialY?: number, initialWidth?: number; initialHeight?: number } }
    | { type: 'windowResize'; size: { width: number; height: number } }
    | { type: 'drag'; id: ModalID; x: number; y: number }
    // | {
    //       type: 'resize'
    //       id: ModalID
    //       x: number
    //       y: number
    //       width: number
    //       height: number
    //   }

export const getModalState = ({
    state,
    id,
    initialX,
    initialY,
    initialWidth,
    initialHeight,
    initialZIndex,
}: {
    state: ModalsState
    id: ModalID
} & ModalStateProps): ModalState => state.modals[id] || getInitialModalState({ initialX, initialY, initialWidth, initialHeight, initialZIndex })

const getNextZIndex = (state: ModalsState, id: string): number =>
    getModalState({ state, id }).zIndex === state.maxZIndex ? state.maxZIndex : state.maxZIndex + 1

const clampDrag = (
    windowWidth: number,
    windowHeight: number,
    x: number,
    y: number,
    width: number,
    height: number,
): { x: number; y: number } => {
  const edge = 12 // 12px安全距离
  const maxX = windowWidth - width - edge
  const maxY = windowHeight - height - edge
  const clampedX = clamp(edge, maxX, x)
  const clampedY = clamp(edge, maxY, y)
  return { x: clampedX, y: clampedY }
}

// const clampResize = (
//     windowWidth: number,
//     windowHeight: number,
//     x: number,
//     y: number,
//     width: number,
//     height: number,
// ): { width: number; height: number } => {
//     const maxWidth = windowWidth - x
//     const maxHeight = windowHeight - y
//     const clampedWidth = clamp(260, maxWidth, width)
//     const clampedHeight = clamp(400, maxHeight, height)
//     return { width: clampedWidth, height: clampedHeight }
// }

export const draggableModalReducer = (state: ModalsState, action: Action): ModalsState => {
    switch (action.type) {
        // case 'resize':
        //     const size = clampResize(
        //         state.windowSize.width,
        //         state.windowSize.height,
        //         action.x,
        //         action.y,
        //         action.width,
        //         action.height,
        //     )
        //     return {
        //         ...state,
        //         maxZIndex: getNextZIndex(state, action.id),
        //         modals: {
        //             ...state.modals,
        //             [action.id]: {
        //                 ...state.modals[action.id],
        //                 ...size,
        //                 zIndex: getNextZIndex(state, action.id),
        //             },
        //         },
        //     }
        case 'drag':
            return {
                ...state,
                maxZIndex: getNextZIndex(state, action.id),
                modals: {
                    ...state.modals,
                    [action.id]: {
                        ...state.modals[action.id],
                        ...clampDrag(
                            state.windowSize.width,
                            state.windowSize.height,
                            action.x,
                            action.y,
                            state.modals[action.id].width,
                            state.modals[action.id].height,
                        ),
                        zIndex: getNextZIndex(state, action.id),
                    },
                },
            }
        case 'show': {
            // const modalState = state.modals[action.id] // 业务逻辑，不记位置。所以不取modal，取初始值
            const initialState = getInitialModalState(action.intialState)
            const centerX = initialState.x || state.windowSize.width / 2 - initialState.width / 2
            const centerY = initialState.y || state.windowSize.height / 2 - initialState.height / 2
            const position = clampDrag(
                state.windowSize.width,
                state.windowSize.height,
                centerX,
                centerY,
                initialState.width,
                initialState.height,
            )
            // const size = clampResize(
            //     state.windowSize.width,
            //     state.windowSize.height,
            //     position.x,
            //     position.y,
            //     initialState.width,
            //     initialState.height,
            // )
            return {
                ...state,
                maxZIndex: state.maxZIndex + 1,
                modals: {
                    ...state.modals,
                    [action.id]: {
                        ...initialState,
                        ...position,
                        // ...size,
                        zIndex: state.maxZIndex + 1,
                        visible: true,
                    },
                },
            }
        }
        case 'focus':
            const modalState = state.modals[action.id]
            return {
                ...state,
                maxZIndex: state.maxZIndex + 1,
                modals: {
                    ...state.modals,
                    [action.id]: {
                        ...modalState,
                        zIndex: state.maxZIndex + 1,
                    },
                },
            }
        case 'hide': {
            const modalState = state.modals[action.id]
            return {
                ...state,
                modals: {
                    ...state.modals,
                    [action.id]: {
                        ...modalState,
                        visible: false,
                    },
                },
            }
        }
        case 'mount':
            const initialState = getInitialModalState(action.intialState)
            return {
                ...state,
                maxZIndex: state.maxZIndex + 1,
                modals: {
                    ...state.modals,
                    [action.id]: {
                        ...initialState,
                        x: initialState.x || state.windowSize.width / 2 - initialState.width / 2,
                        y: initialState.y || state.windowSize.height / 2 - initialState.height / 2,
                        zIndex: state.maxZIndex + 1,
                    },
                },
            }
        case 'unmount':
            const modalsClone = { ...state.modals }
            delete modalsClone[action.id]
            return {
                ...state,
                modals: modalsClone,
            }
        case 'windowResize':
            return {
                ...state,
                windowSize: action.size,
                modals: mapObject(state.modals, (modalState: ModalState) => {
                    if (!modalState.visible) {
                        return modalState
                    }
                    const position = clampDrag(
                        state.windowSize.width,
                        state.windowSize.height,
                        modalState.x,
                        modalState.y,
                        modalState.width,
                        modalState.height,
                    )
                    // 暂不支持resize
                    // const size = clampResize(
                    //     state.windowSize.width,
                    //     state.windowSize.height,
                    //     position.x,
                    //     position.y,
                    //     modalState.width,
                    //     modalState.height,
                    // )
                    return {
                        ...modalState,
                        ...position,
                        // ...size,
                    }
                }),
            }
        default:
            throw new Error()
    }
}
