import React, { useContext, useEffect, useRef, useState } from 'react'
import { findLastChildElement, omegaEvent, renderMarkdown } from '../browser/utils'
import { OmegaEventId } from '../browser/omega'
import { resetQaContext } from '../request'
import { ResponseTip } from '../browser/constant'
import IconStop from './icon/IconStop'
import IconReplay from './icon/IconReplay'
import IconLike from './icon/IconLike'
import IconDislike from './icon/IconDislike'
import { CooperImagePreview } from '@didi/cooper-image-preview'
import { EventStreamContentType, fetchEventSource } from '@microsoft/fetch-event-source'
import { RootContext, update_isLoading } from './Root'
import { intl } from '../browser/locales'

enum IsLike {
  empty,
  like,
  dislike,
}

enum Status {
  pending,
  progress,
  fulfilled,
  rejected,
}

const MaxErrorTimes = 3

const cursor = document.createElement('span')
cursor.className = 'icschat-cursor-blink'

function Pending() {
  return (
    <div className={'icschat-pending'}>
      <span />
      <span />
      <span />
    </div>
  )
}

interface AnswerProps {
  question: string
  isNeedResetContext: boolean
}

class RetriableError extends Error {}
class FatalError extends Error {}

function Answer({ question, isNeedResetContext }: AnswerProps) {
  const [content, setContent] = useState('') // 渲染的内容
  const [status, setStatus] = useState<Status>(Status.pending)
  const [isLike, setIsLike] = useState<IsLike>(IsLike.empty)
  // 预览的图片
  const [images, setImages] = useState<string[]>([])
  // 预览第n张图， null表示不预览
  const [imageIndex, setImageIndex] = useState<null | number>(null)

  const busiMessageIdRef = useRef('')
  const errorTimesRef = useRef(0)
  const sourceRef = useRef<any>()
  const { store: { isLoading }, dispatch } = useContext(RootContext);
  const [enableRetry, setEnableRetry] = useState(false)
  const timerRef = useRef(0)
  const htmlRef = useRef<HTMLDivElement>(null)
  const disableScrollRef = useRef(false)

  const openContext = () => {
    return new Promise<{ success: boolean }>((resolve) => {
      if (isNeedResetContext) {
        resetQaContext().then(resolve)
      } else {
        resolve({
          success: true,
        })
      }
    })
  }

  const startGenerate = (_renew = false) => {
    dispatch({
      type: update_isLoading,
      value: true
    });
    sourceRef.current = new AbortController()
    const url = `/cooper_gateway/callback/v1/llm/bot/qa`
    // const config = {
    //   signal: sourceRef.current.signal,
    //   withCredentials: true,
    //   headers: {
    //     'Content-Type': 'application/json',
    //   },
    //   onDownloadProgress(d: any) {
    //     const { responseText } = d?.event?.target || d?.target
    //     try {
    //       const data = JSON.parse(responseText)
    //       if (!data.success) {
    //         const errorCode: keyof typeof ResponseTip = data.errorCode
    //         const errCodeTip = ResponseTip[errorCode]
    //         setContent(errCodeTip)
    //         setEnableRetry(true)
    //         setStatus(Status.rejected)
    //       }
    //     } catch (e) {
    //       const list = responseText
    //         .split(/\n\n|data:/)
    //         .filter(Boolean)
    //         .map((s: string) => JSON.parse(s))
    //       const result = list.map((item: any) => item.result).join('')
    //       const isEnd = list.some((item: any) => item.isEnd)
    //       busiMessageIdRef.current = list[0]?.id
    //       if (result === ResponseTip.unknown) {
    //         setContent(ResponseTip.fail)
    //         setStatus(Status.rejected)
    //       } else {
    //         clearInterval(timerRef.current)
    //         timerRef.current = window.setInterval(() => {
    //           setContent((preVal) => {
    //             const val = result.substring(0, preVal.length + 1)
    //             if (result === val) {
    //               clearInterval(timerRef.current)
    //               if (isEnd) {
    //                 setStatus(Status.fulfilled)
    //               }
    //             }
    //             return val
    //           })
    //         }, 6) // 60
    //         setContent(result)
    //         setStatus(isEnd ? Status.fulfilled : Status.rejected)
    //       }
    //     }
    //   },
    // }
    // const params = {
    //   input: question,
    // }
    openContext()
      .then((data) => {
        if (data.success) {
          return fetchEventSource(url, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              input: question,
            }),
            signal: sourceRef.current.signal,
            async onopen(response) {
              if (
                response.ok &&
                (response.headers.get('content-type') === EventStreamContentType ||
                  response.headers.get('content-type') === 'text/event-stream;charset=UTF-8')
              ) {
                return // everything's good
              } else if (
                response.status >= 400 &&
                response.status < 500 &&
                response.status !== 429
              ) {
                // client-side errors are usually non-retriable:
                setStatus(Status.rejected)
                setContent(ResponseTip.fail)
                throw new FatalError()
              } else {
                setStatus(Status.rejected)
                setContent(ResponseTip.fail)
                throw new RetriableError()
              }
            },
            onmessage(msg) {
              if (msg.data) {
                const json = JSON.parse(msg.data) // {result: '', isEnd: false}
                setContent((preVal) => {
                  return preVal + json.result
                })
                if (json.isEnd) {
                  setStatus(Status.fulfilled)
                  // 传完后，断开连接
                  sourceRef.current?.abort?.()
                  return
                }
              }
              // if the server emits an error message, throw an exception
              // so it gets handled by the onerror callback below:
              if (msg.event === 'FatalError') {
                throw new FatalError(msg.data)
              }
            },
            onclose() {
              // if the server closes the connection unexpectedly, retry:
              throw new RetriableError()
            },
            onerror(err) {
              if (err instanceof FatalError) {
                setContent(ResponseTip.fail)
                setStatus(Status.rejected)
                throw err // rethrow to stop the operation
              } else {
                setContent(ResponseTip.fail)
                setStatus(Status.rejected)
                // do nothing to automatically retry. You can also
                // return a specific retry interval here.
              }
            },
          })
          // return axiosInstance.post(url, params, config)
        }
        // setStatus(Status.rejected)
        if (!data.success) {
          throw new Error('重制会话失败')
        }
        return
      })
      .then(() => {
        errorTimesRef.current = 0
      })
      .catch((err) => {
        if (err.name === 'CanceledError') return
        errorTimesRef.current++
        const errTip =
          errorTimesRef.current > MaxErrorTimes ? ResponseTip.timeLimit : ResponseTip.fail
        setContent(errTip)
        setStatus(Status.rejected)
      })
  }

  const regenerate = () => {
    omegaEvent(OmegaEventId.regenerate, '', {})

    setContent('')
    setStatus(Status.pending)
    setIsLike(IsLike.empty)
    setEnableRetry(false)

    startGenerate(true)
  }

  const stopGenerate = () => {
    omegaEvent(OmegaEventId.stop, '', {})
    sourceRef.current?.abort?.()
    errorTimesRef.current = 0
    // 停止生成后， 有重新生成
    setEnableRetry(true)
    clearInterval(timerRef.current)
    if (!content) {
      setContent(ResponseTip.fail)
      setStatus(Status.rejected)
    } else {
      setStatus(Status.fulfilled)
    }
  }

  const handleScore = (val: IsLike) => {
    if (val === IsLike.like) {
      // 点击有帮助
      setIsLike(isLike === IsLike.like ? IsLike.empty : IsLike.like)
      omegaEvent(
        isLike === IsLike.like ? OmegaEventId.cancelLike : OmegaEventId.likeSummary,
        '',
        {
          busi_message_id: busiMessageIdRef.current,
        },
      )
    } else {
      // 点击无帮助
      setIsLike(isLike === IsLike.dislike ? IsLike.empty : IsLike.dislike)
      omegaEvent(
        isLike === IsLike.dislike ? OmegaEventId.cancelDislike : OmegaEventId.dislikeSummary,
        '',
        {
          busi_message_id: busiMessageIdRef.current,
        },
      )
    }
  }

  const scrollIntoView = (ele: HTMLButtonElement) => {
    // 如果用户向上滚动过，则不再自动定位
    if (disableScrollRef.current) return
    ele?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    })
  }

  useEffect(() => {
      if (status === Status.fulfilled || status === Status.rejected) {
          dispatch({
              type: update_isLoading,
              value: false
          })
      }
  }, [status])

  useEffect(() => {
    startGenerate()

    const box = document.querySelector('.icschat-main-content')!
    let scrollTop: number | null = null
    const handleScroll = () => {
      if (scrollTop && box.scrollTop < scrollTop) {
        disableScrollRef.current = true
        box.removeEventListener('scroll', handleScroll)
      }
      scrollTop = box.scrollTop
    }
    box.addEventListener('scroll', handleScroll)

    return () => {
      box.removeEventListener('scroll', handleScroll)
    }
  }, [])

  useEffect(() => {
    if (status === Status.pending) {
      cursor.remove()
      const ele = findLastChildElement(htmlRef.current)
      ele?.appendChild(cursor)
    } else {
      cursor.remove()
    }
    if (status === Status.fulfilled) {
      // 处理要预览的图片
      let images = []
      // @ts-ignore
      let imageDoms: HTMLCollectionOf = htmlRef.current.querySelectorAll('img')
      for (let i = 0; i < imageDoms.length; i++) {
        imageDoms[i].addEventListener('click', () => {
          setImageIndex(i)
        })
        images.push(imageDoms[i].src as string)
      }
      if (images.length) {
        setImages(images)
      }
      // 处理参考链接
      // @ts-ignore
      let linkDoms: HTMLCollectionOf = htmlRef.current.querySelectorAll('a')
      for (let i = 0; i < linkDoms.length; i++) {
        linkDoms[i]?.addEventListener('click', () => {
          omegaEvent(OmegaEventId.referenceSource, '', {})
        })
      }
    }
  }, [content, status])

  if (status === Status.pending && !content) {
    return (
      <div className={'icschat-generate-summary-pending-wrapper'}>
        <div className={'icschat-generate-summary-pending'}>
          <Pending />
        </div>
        <button
          ref={scrollIntoView}
          className={'icschat-button icschat-button-stop'}
          onClick={stopGenerate}
        >
          <IconStop />
          <span>{intl('停止生成')}</span>
        </button>
      </div>
    )
  }

  return (
    <div
      className={`icschat-generate-answer-wrapper icschat-message-card ${
        status === Status.pending ? 'pending' : ''
      }`}
    >
      <div className={'icschat-generate-answer icschat-message-card-layout'}>
        <div
          ref={htmlRef}
          className={'icschat-generate-summary-text icschat-html'}
          dangerouslySetInnerHTML={{ __html: renderMarkdown(content) }}
        ></div>

        {status !== Status.pending && (
          <div className={'icschat-generate-summary-footer'}>
            {enableRetry && (
              <button
                data-title={intl('重新生成')}
                className={'icschat-button icschat-icon-button'}
                onClick={regenerate}
                disabled={isLoading}
              >
                <IconReplay />
              </button>
            )}
            <button
              data-title={intl('有帮助')}
              className={`icschat-button icschat-icon-button ${
                isLike === IsLike.like ? 'active' : ''
              }`}
              onClick={() => handleScore(IsLike.like)}
            >
              <IconLike />
            </button>
            <button
              data-title={intl('无帮助')}
              className={`icschat-button icschat-icon-button ${
                isLike === IsLike.dislike ? 'active' : ''
              }`}
              onClick={() => handleScore(IsLike.dislike)}
            >
              <IconDislike />
            </button>
          </div>
        )}
      </div>
      {status === Status.pending && (
        <button
          ref={scrollIntoView}
          className={'icschat-button icschat-button-stop'}
          onClick={stopGenerate}
        >
          <IconStop />
          <span>{intl('停止生成')}</span>
        </button>
      )}
      {imageIndex !== null && (
        <CooperImagePreview
          imgFiles={images}
          fidx={imageIndex}
          closePreview={() => setImageIndex(null)}
        />
      )}
    </div>
  )
}

export default Answer
