import React from 'react'

const IconReplay = () => (
  <svg
    className='didoc-component-icon'
    width="200px"
    height="200px"
    viewBox="0 0 1024 1024"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M549.824 85.44q176.64 0 301.568 124.928T976.32 512q0 176.64-124.928 301.632-124.928 124.928-301.632 124.928-176.64 0-301.632-124.928Q123.2 688.64 123.2 512q0-56.896 14.592-110.976l-39.68 22.912a42.56 42.56 0 0 1-42.56-73.728l132.992-76.8a42.56 42.56 0 0 1 58.112 15.616l76.8 132.992a42.56 42.56 0 1 1-73.664 42.56l-27.904-48.256Q208.32 462.848 208.32 512q0 141.44 100.032 241.408 99.968 100.032 241.408 100.032t241.472-100.032Q891.2 653.44 891.2 512t-99.968-241.408Q691.2 170.56 549.76 170.56a42.56 42.56 0 1 1 0-85.12h0.064z" />
  </svg>
)
export default IconReplay
