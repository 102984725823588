import React, { useContext } from 'react'
import { add_messages, Role, RootContext } from './Root'
import { uuid } from '../browser/utils'
import Answer from './Answer'
import { intl } from '../browser/locales'

function Greeting(props: { dispatch: any }) {
  const {
    store: { isLoading },
  } = useContext(RootContext)
  const onClick = (qa: string) => {
    if (isLoading) return
    const messages = [
      {
        id: uuid(),
        role: Role.user,
        body: qa,
        createTime: Date.now(),
        group: 'qa',
      },
      {
        id: uuid(),
        role: Role.assistant,
        body: <Answer isNeedResetContext={false} question={qa} />,
        createTime: Date.now(),
        group: 'qa',
      },
    ]

    props.dispatch({
      type: add_messages,
      value: messages,
    })
  }
  const list = [intl('如何修改团队空间成员权限？'), intl('文档有查看权限，如何申请编辑权限？')]
  return (
    <div className={'icschat-greeting-home'}>
      <p>{intl('你好，我是Cooper智能客服。你可以简单描述一下问题，例如：')}</p>
      {list.map((item, index) => (
        <p key={index}>
          <a onClick={() => onClick(item)}>{item}</a>
        </p>
      ))}
    </div>
  )
}

export default Greeting
