import React from 'react'

const IconDislike = () => (
  <svg
    className="didoc-component-icon didoc-component-icon-dislike"
    width="200px"
    height="200px"
    viewBox="0 0 1024 1024"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M937.6 199.04A128 128 0 0 0 896 117.12a128 128 0 0 0-87.04-31.36H256a113.28 113.28 0 0 0-78.08 28.16 113.92 113.92 0 0 0-40.96 72.96l-53.12 345.6a113.92 113.92 0 0 0 28.16 96 114.56 114.56 0 0 0 91.52 41.6h188.8v110.72a158.72 158.72 0 0 0 157.44 158.08 43.52 43.52 0 0 0 39.04-25.6l140.16-316.16h76.16A122.88 122.88 0 0 0 896 562.56a134.4 134.4 0 0 0 43.52-83.2 17.92 17.92 0 0 0 0-5.76V204.8a17.92 17.92 0 0 0-1.92-5.76z m-276.48 342.4l-136.96 307.84a75.52 75.52 0 0 1-25.6-17.28 69.76 69.76 0 0 1-21.12-51.2V627.2a42.24 42.24 0 0 0-42.88-42.24H203.52a34.56 34.56 0 0 1-33.92-39.68l53.12-345.6A30.08 30.08 0 0 1 256 170.88h403.84z m192-71.04a51.2 51.2 0 0 1-16.64 29.44 39.04 39.04 0 0 1-29.44 12.16h-60.8V170.88h60.16a42.88 42.88 0 0 1 31.36 10.88 44.8 44.8 0 0 1 15.36 26.24z" />
  </svg>
)
export default IconDislike
